footer {
    background-color: #000;
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.footer-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 64px;
}

.footer-header > svg {
    height: 53px;
    margin-right: -20px;
}

.footer-socials {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    height: 31px;
}

.footer-socials > a {
    color: #cccccc;
    transition: 300ms;
    width: 24px;
}

.footer-socials > a :hover {
    color: #fff;
    transition: 300ms;
}

.policy {
    display: flex;
    gap: 25px;
    align-items: center;
}

.policy > a:hover {
    transition: 300ms;
    color: #fff;
}

.policy > a,
.policy > p {
    transition: 300ms;
    font-size: 15px;
    color: #6c6c6c;
    line-height: 28px;
    font-family: 'Roobert';
    font-weight: 400;
}

@media screen and (max-width: 700px) {
    footer {
        padding: 50px 50px;
        align-items: flex-start;
    }

    .footer-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }

    .footer-header > svg {
        height: 90px;
        margin-bottom: -30px;
        margin-left: -30px;
        margin-top: -30px;
    }

    .policy {
        width: 100%;
        justify-content: space-between;
    }

    .policy > p {
        font-size: 14px;
        line-height: 16px;
    }

    .policy > a {
        font-size: 14px;
        line-height: 16px;
    }
}
