.border {
    padding-inline: 100px;
}

.presentation {
    height: calc(100vh - 240px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding-bottom: 240px;
    gap: 40px;
}

.presentation > h1 {
    font-size: 50px;
    line-height: 73px;
    font-weight: 400;
}

.presentation > p {
    width: 880px;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
}

.secret-container {
    background-color: #000;
}

.secret {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.img-3lly > div {
    width: 100%;
}

.img-3lly > img {
    width: 100%;
}

#lottie-container > div {
    margin-bottom: -4px;
}

.secret-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 120px 0px;
    padding-right: 100px;
    gap: 70px;
    height: calc(100vh - 240px);
}

.secret-text-container > h2 {
    color: #fff;
    font-weight: 700;
    font-size: 50px;
}

.secret-text-container > p {
    color: #fff;
    opacity: 0.8;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    width: 100%;
}

.services {
    margin-top: 50px;
}

.services-title > h5 {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 25px;
}

.services-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
}

.services-cards > div {
    width: 100%;
    padding-inline: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.services-cards > * {
    text-align: center;
}

.service-card {
    transition: 300ms;
    position: relative;
    overflow: hidden;
}

.service-cards-details {
    z-index: 1;
}

.service-cards-details > h2 {
    color: #fff;
    font-family: 'Roobert';
    line-height: 61px;
    font-size: 35px;
    text-transform: capitalize;
    font-weight: 400;
    user-select: none;
    z-index: 1;
}
.service-cards-details > p {
    color: #fff;
    font-family: 'Roobert';
    line-height: 26px;
    font-size: 18px;
    width: 870px;
    margin-top: 10px;
    user-select: none;
}

.works-frame-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.works-grid {
    display: grid;
    height: calc(100vh - 70px);
    /* width: 1300px; */
    /* min-width: 70%; */
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    aspect-ratio: 2/1.3;
    gap: 20px;
    margin-top: 45px;
}

.works-grid > div {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    position: relative;
    overflow: hidden;
}

.carousel-1 {
    grid-row-start: 1;
    grid-row-end: 3;
    aspect-ratio: 1/2.1;
}

.carousel-1,
.carousel-2,
.carousel-4,
.carousel-5 {
    aspect-ratio: 1;
}

.splide {
    height: 100% !important;
}

.splide__pagination__page {
    height: 2px !important;
    min-width: 40px;
    max-width: 60px !important;
    border-radius: 0 !important;
    background-color: hsla(0, 0%, 85%, 1) !important;
    opacity: 1 !important;
}

.splide__track {
    height: 100%;
}

.splide__pagination__page {
    background-color: hsla(0, 0%, 0%, 0.3) !important;
    border-radius: 24px !important;
    height: 3px !important;
    width: 60px !important;
}

.splide__pagination__page.is-active {
    background-color: #fff !important;
    transform: none !important;
}

.splide__pagination {
    width: 100% !important;
    padding: 0 !important;
    gap: 5px !important;
    bottom: 50px !important;
}

.splide-inner {
    height: calc(100% - 100px);
    background-size: cover;
    background-position: center;
    padding: 50px 50px;
}

.splide-inner > a > svg {
    scale: 0.8;
    transition: 300ms;
}

.splide-inner > a > svg:hover {
    scale: 1;
    transition: 300ms;
}

.splide-inner > div {
    border-radius: 5px;
    display: inline-block;
}

.splide-inner > div > h3 {
    text-transform: uppercase;
    padding: 8px;
    font-size: 1.04vw;
    font-family: 'Roobert';
    font-weight: 600;
    width: auto;
}
.splide-inner > h2 {
    font-size: 1.74vw;
    font-family: 'Roobert';
    font-weight: 600;
    width: 200px;
    margin-top: 10px;
    width: 100%;
}

.contacts {
    background-color: #000;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 97px;
    padding-top: 70px;
    padding-bottom: 125px;
    border-bottom: 1.3px solid #525252;
}

.contacts-header {
    width: 100%;
    text-align: center;
}

.contacts-header > h2 {
    font-size: 40px;
    line-height: 61px;
    font-weight: 400;
    color: #fff;
}

.contacts-header > p {
    font-size: 18px;
    line-height: 26px;
    color: #d0d0d0;
    margin-top: 2px;
}

.contacts-methods {
    margin-top: 50px;
    display: flex;
    gap: 20px;
    align-items: center;
}

.methods-frame {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    background: #fff;
    border-radius: 25px;
    padding: 20px 20px;
    width: 250px;
}

.methods-frame > svg {
    width: 43px;
    height: 27px;
    transition: 300ms;
    color: #b7b7b7;
}

.methods-frame > div > h3 {
    font-size: 18px;
    font-family: 'Roobert';
    font-weight: 600;
    color: #000;
}

.methods-frame > div > p {
    font-size: 15px;
    font-family: 'Roobert';
    font-weight: 400;
    margin-top: 6px;
    width: calc(100% - 30px);
    color: #000;
    opacity: 0.8;
    padding-inline: 15px;
    text-align: center;
}

.presentation > svg {
    display: none;
}

.press {
    border-radius: 100%;
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 300ms;
    z-index: 0;
    animation: press-an 1700ms 3;
}

.img-3lly {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
}

@keyframes press-an {
    10% {
        border: 0 solid rgba(255, 255, 255, 0.2);
    }

    50% {
        border: 100px solid rgba(255, 255, 255, 0.2);
        background-color: rgba(255, 255, 255, 0.2);
    }

    75% {
        border: 60px solid rgba(255, 255, 255, 0.2);
        height: 0;
        width: 0;
    }

    100% {
        height: 200px;
        width: 200px;
        border: 10px solid rgba(255, 255, 255, 0);
        /* background-color: transparent; */
    }
}

.w-watermark-wrapper {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    opacity: 0.3;
}

.splide,
.splide__track {
    border-radius: 40px;
    overflow: hidden;
}

.team-frame {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    padding-bottom: 65px;
}

.team-main {
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}

.team-img-frame {
    position: relative;
}

.plus-icon {
    position: absolute;
    top: 5px;
    right: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
}

.team-image {
    width: 150px;
    aspect-ratio: 1;
    border-radius: 100%;
}

.team-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.team-details > h2,
.team-details > p {
    text-transform: capitalize;
}

.popup {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.popup-frame {
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    display: flex;
}

.popup-text > h2 {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 5px;
}

.back-icon {
    position: absolute;
    top: -15px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 15px;
    background-color: #000;
    border-radius: 100%;
    cursor: pointer;
}

.back-size {
    width: 15px;
    height: 15px;
}

.popup-details-mobile {
    display: none;
}

.popup-text {
    padding: 50px 60px;
    max-width: 400px;
}

.img-prof {
    width: 100%;
    height: 80%;
    border-top-left-radius: 8px;
    background-size: cover;
    min-height: 300px;
}

.popup-details {
    height: 100%;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    background-color: #000;
    color: #fff;
}

.popup-details > :last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0px;
}

.popup-details :last-child > p {
    opacity: 0.6;
}

@media screen and (max-width: 700px) {
    .splide-inner > h2 {
        font-size: 25px;
    }
    .splide-inner > div > h3 {
        font-size: 15px;
    }

    .press {
        display: unset;
    }

    .border {
        width: calc(100vw - 100px);
        padding-inline: 50px;
    }

    .presentation > svg {
        display: block;
        width: 110px;
        height: 80px;
        margin-top: 50px;
    }

    .presentation {
        height: 100%;
    }

    .presentation > h1 {
        font-size: 40px;
        line-height: 45px;
    }

    .presentation > p {
        width: 100%;
        font-size: 20px;
        margin-top: -20px;
    }

    .secret {
        display: flex;
        flex-direction: column-reverse;
    }

    .secret-text-container {
        gap: 30px;
        width: calc(100% - 100px);
        padding: 30px 50px;
        justify-content: unset;
        height: calc(680px - 60px);
    }

    .secret-text-container > h2 {
        font-size: 45px;
    }

    .secret-text-container > p {
        font-size: 20px;
    }

    .service-cards-details > h2 {
        font-size: 30px;
    }

    .service-cards-details > p {
        width: calc(100% - 60px);
        padding-inline: 30px;
    }

    .works {
        height: calc((530px * 5) + (20px * 5));
    }

    .works-grid {
        display: block;
        width: 100%;
        height: 100%;
        max-width: unset;
        min-width: unset;
        min-height: unset;
        max-height: unset;
    }

    .works-grid > div {
        height: 530px;
        margin-bottom: 20px;
    }

    .carousel-1,
    .carousel-2,
    .carousel-3,
    .carousel-4,
    .carousel-5 {
        aspect-ratio: unset;
    }

    .splide-inner {
        height: calc(100% - 60px);
        padding: 30px 30px;
    }

    .splide__track {
        border-radius: 40px;
        overflow: hidden;
    }

    .contacts {
        width: calc(100% - 100px);
        padding-inline: 50px;
        padding-bottom: 70px;
        padding-top: 30px;
    }

    .contacts-header > h2 {
        text-align: left;
        line-height: 45px;
    }

    .contacts-header > p {
        text-align: left;
        margin-top: 5px;
    }

    .contacts-methods {
        width: 100%;
        gap: 10px;
        flex-direction: column;
    }

    .contacts-methods > a {
        width: 100%;
    }

    .contacts-methods > div > h3 {
        font-size: 17px;
    }

    .methods-frame > div > h3 {
        font-size: 17px;
    }

    .methods-frame {
        gap: 10px;
        width: calc(100% - 20px);
        padding: 20px 10px;
        border-radius: 27px;
    }

    .methods-frame > svg {
        width: 50px;
    }

    .methods-frame > div {
        align-items: center;
        justify-content: center;
        width: 190px;
    }

    .methods-frame > div > p {
        margin-top: 5px;
        font-size: 12px;
    }

    .service-cards-details {
        width: calc(100% - 40px);
        padding-inline: 20px;
    }

    .services,
    .services-cards {
        margin-top: 40px;
    }

    #team-sec {
        margin-bottom: 20px;
    }

    .team-frame {
        flex-direction: column;
        align-items: flex-start;
        gap: 50px;
        padding: 30px 0px;
    }

    .team-image {
        width: 110px;
    }

    .plus-icon {
        width: 25px;
        height: 25px;
    }

    .team-details {
        gap: 5px;
    }

    .team-details > h2 {
        font-size: 20px;
        line-height: 20px;
    }

    .popup-frame {
        width: calc(100% - 40px);
        max-width: unset;
        padding: 30px 20px;
        flex-direction: column;
    }

    .popup {
        width: calc(100% - 60px);
        padding-inline: 30px;
    }

    .popup-text {
        padding: 0;
    }

    .popup-text > p {
        font-size: 13px;
    }

    .popup-details-mobile {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .popup-details-mobile > svg {
        display: none;
    }

    .popup-details-mobile > div > h2 {
        line-height: 20px;
    }

    .popup-details-mobile > div > p {
        opacity: 0.6;
    }

    .popup-details > img {
        border-radius: 100%;
        width: 100px;
        aspect-ratio: 1;
    }

    .popup-details-mobile > svg {
        display: block;
        width: 60px;
        margin-left: -15px;
    }

    .popup-details {
        display: none;
    }
}
