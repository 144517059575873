nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: calc(100% - 200px);
    z-index: 100;
}

.blur {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(25px);
}

.right {
    z-index: 1;
    display: flex;
    gap: 40px;
    align-items: center;
}

.right > ul {
    display: flex;
    list-style: none;
    gap: 40px;
    text-transform: capitalize;
    align-items: center;
}

.right > ul > a > li {
    color: #363636;
    transition: 300ms;
}

.right > ul > a > li:hover {
    color: #000;
    transition: 300ms;
}

.loader {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    transition: all 0.3s ease;
    outline: none;
    border: 0;
    background-color: transparent;
    border: 2px solid #e6e6e6;
    rotate: -90deg;
}

.circle-progress {
    position: absolute;
    left: -2px;
    top: -2px;
}

.left {
    display: flex;
    align-items: center;

    z-index: 1;
}

.contact-header-btn {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-direction: row;
    justify-content: center;
    font-family: 'Roobert';
    background: #f9f9f9;
    border-radius: 100px;
    padding: 8px 15px;
}

.contact-header-btn > span {
    margin-top: 3px;
}

@media screen and (max-width: 700px) {
    nav {
        bottom: 30px;
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        padding: 15px 21px;
        border-radius: 40px;
        transition: 200ms;
    }

    .right {
        width: calc(330px - 60px);
        gap: 25px;
        position: absolute;
        bottom: calc(100% + 30px);
        flex-direction: column;
        left: 50%;
        transform: translateX(-50%);
        padding: 20px 30px;
        border-radius: 40px;
        overflow: hidden;
        border-radius: 21px;
        transition: 300ms;
    }

    .right > ul {
        padding-inline-start: 0;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    .mobile {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: center;
        /* z-index: 1; */
    }

    .blur {
        z-index: -1;
    }

    .mobile > h2 {
        font-size: 18px;
        font-family: 'Roobert';
        font-weight: 400;
    }

    .right > a {
        text-transform: capitalize;
    }

    .right > a {
        width: 100%;
        height: 50px;
        border-radius: 100px;
    }
}
