html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Roobert';
    src: url('./assets/fonts/Roobert-Regular.woff2') format('woff2');
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

h1,
h2,
h5 {
    font-family: 'Roobert';
}

p {
    font-family: 'Roobert';
}

li {
    font-size: 15px;
    color: #363636;
    font-family: 'Roobert';
}

a {
    text-decoration: none;
}
